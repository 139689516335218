import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { A11y, Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { BLACK, DEEPGREEN, GRAY_01, GRAY_02, GRAY_03, WHITE } from '../../constants/colors';
import { INNER_ELEMENT_WIDTH, WINDOW_MIN_WIDTH } from '../../constants/layout';
import { ANCHOR_IDS, OUTER_URLS } from '../../constants/urls';
import { HeaderMenuType, HeaderStyleType } from '../../data/header';
import { vCareData, VCareMenu, VCareMenuType } from '../../data/vCare';
import useChangeHeaderStyle from '../../hooks/useChangeHeaderStyle';
import { getDesktopStyle, showOnDesktopStyle, showOnMobileStyle } from '../../styles/layout';
import { swiperPagenationStyle } from '../../styles/swiper';
import { pxToRem } from '../../utils/common';
import AnchorLink from '../AnchorLink';
import MobileLineLinkButton from '../common/MobileLineLinkButton';
import Outlink from '../common/Outlink';
import SectionTitleSet from '../common/SectionTitleSet';
import MenuIconItem from '../vCareSection/MenuIconItem';

const VCareSection = () => {
	const sectionRef = useRef<HTMLElement>(null);
	const [selectedType, setSelectedType] = useState<VCareMenuType>(VCareMenuType.Kinder);
	const { info, serviceName, tags, images } = useMemo(() => vCareData[selectedType], [selectedType]);
	useChangeHeaderStyle({
		sectionRef,
		currentType: HeaderMenuType.Businesses,
		nextType: HeaderMenuType.Contact,
		currentStyleType: HeaderStyleType.White,
		nextStyleType: HeaderStyleType.Black,
	});

	const onClickMenuItem = useCallback((type: VCareMenuType) => {
		setSelectedType(type);
	}, []);

	return (
		<>
			<AnchorLink id={ANCHOR_IDS.VCARE} />
			<Wrapper ref={sectionRef}>
				<ContentWrapper>
					<TitleWrapper>
						<SectionTitleSet
							title="V+Care"
							description={
								<>
									<span css={showOnDesktopStyle}>
										펫닥의 수의사들을 직접 만나볼 수 있는 오프라인 플랫폼.
										<br />
										안전하고 체계적인 커리큘럼으로 운영되는 반려견 유치원과 케어 서비스가 운영되고 있습니다.
									</span>
									<span css={showOnMobileStyle}>
										펫닥의 수의사들을 직접 만나볼 수 있는 오프라인 플랫폼.
										<br />
										안전하고 체계적인 커리큘럼으로 운영되는
										<br />
										반려견 유치원과 케어 서비스가 운영되고 있습니다.
									</span>
								</>
							}
							textAlign="LEFT"
						/>
					</TitleWrapper>
					<CareWrapper>
						<Outlink
							text="SNS로 바로가기"
							href={OUTER_URLS.VCARE}
							color={DEEPGREEN}
							styles={css`
								margin-top: ${pxToRem(32)};
							`}
						/>
						<MobileButtonWrapper>
							<MobileLineLinkButton link={OUTER_URLS.VCARE} type="BLACK">
								SNS로 바로가기
							</MobileLineLinkButton>
						</MobileButtonWrapper>
						<MenuWrapper>
							<TypeIcons>
								{VCareMenu.map((menu) => (
									<MenuIconItem
										key={menu.id}
										isSelected={selectedType === menu.type}
										itemData={menu}
										onClick={onClickMenuItem}
									/>
								))}
							</TypeIcons>
							<DetailWrapper>
								<OpeningHoursWrapper>
									<OpeningHoursTitle>{serviceName} 운영시간</OpeningHoursTitle>
									<OpeningHours>
										{info.map((item, index) => (
											<span key={index}>{item}</span>
										))}
									</OpeningHours>
								</OpeningHoursWrapper>
								<Tags>
									{tags.map((tag, index) => (
										<Tag key={index}>{tag}</Tag>
									))}
								</Tags>
							</DetailWrapper>
						</MenuWrapper>
						<ImagesWrapperDesktop>
							{images.map((image, index) => (
								<Image key={index} src={image} alt="" />
							))}
						</ImagesWrapperDesktop>
						<ImagesWrapperMobile>
							<Swiper
								modules={[Pagination, A11y, Autoplay]}
								loop={true}
								pagination={{
									clickable: true,
								}}
								autoplay={{
									delay: 2000,
									disableOnInteraction: false,
								}}
								spaceBetween={15}
							>
								{images.map((image, index) => (
									<SwiperSlide key={index}>
										<SlideImage key={index} src={image} alt="" />
									</SwiperSlide>
								))}
							</Swiper>
						</ImagesWrapperMobile>
					</CareWrapper>
				</ContentWrapper>
			</Wrapper>
		</>
	);
};

export default VCareSection;

const SlideImage = styled.img`
	width: 100%;
	height: 69.9vw;
	object-fit: cover;
`;

const ImagesWrapperMobile = styled.div`
	${showOnMobileStyle};

	.swiper {
		height: calc(70vw + 50px);
	}

	${swiperPagenationStyle({ bgColor: GRAY_03, activeColor: BLACK })};
`;

const Image = styled.img`
	box-shadow: 5px 5px 9px rgba(0, 0, 0, 0.25);
`;

const ImagesWrapperDesktop = styled.div`
	${showOnDesktopStyle};
	${getDesktopStyle(css`
		display: flex;
		justify-content: space-between;
		height: ${pxToRem(295)};
	`)}
`;

const Tag = styled.li`
	padding: 0 ${pxToRem(5.5)};
	background-color: ${GRAY_02};
	border-radius: ${pxToRem(7.5)};
	height: ${pxToRem(15)};
	line-height: ${pxToRem(15)};
	font-size: ${pxToRem(9)};
	color: ${BLACK};
	letter-spacing: -0.05em;

	${getDesktopStyle(css`
		padding: 0 ${pxToRem(10)};
		background-color: ${WHITE};
		border-radius: ${pxToRem(10)};
		height: ${pxToRem(20)};
		line-height: ${pxToRem(20)};
		font-size: ${pxToRem(10)};
		color: ${DEEPGREEN};
	`)}
`;

const Tags = styled.ul`
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin-top: ${pxToRem(23)};
	margin-bottom: ${pxToRem(26)};

	${Tag} + ${Tag} {
		margin-left: ${pxToRem(6)};
	}

	${getDesktopStyle(css`
		justify-content: initial;
		margin-top: 0;
		margin-bottom: 0;

		${Tag} + ${Tag} {
			margin-left: ${pxToRem(12)};
		}
	`)}
`;

const OpeningHours = styled.p`
	font-size: ${pxToRem(15)};
	line-height: ${pxToRem(24)};

	span {
		display: block;
	}

	${getDesktopStyle(css`
		font-size: ${pxToRem(11)};

		span {
			display: inline;
		}

		span + span {
			margin-left: ${pxToRem(12)};
			position: relative;

			:before {
				position: absolute;
				left: -7px;
				content: ' · ';
			}
		}
	`)}
`;

const OpeningHoursTitle = styled.p`
	font-size: ${pxToRem(14)};
	font-weight: 700;
	color: ${DEEPGREEN};
	margin-bottom: ${pxToRem(3)};

	${getDesktopStyle(css`
		font-weight: 500;
		font-size: ${pxToRem(15)};
		margin-bottom: ${pxToRem(2)};
	`)}
`;

const OpeningHoursWrapper = styled.div`
	text-align: center;
	margin-top: ${pxToRem(36)};

	${getDesktopStyle(css`
		text-align: left;
		margin-top: 0;
	`)}
`;

const DetailWrapper = styled.div`
	${getDesktopStyle(css`
		height: ${pxToRem(87)};
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		padding: ${pxToRem(2)} 0;
		margin-bottom: ${pxToRem(120)};
	`)};
`;

const TypeIcons = styled.ul`
	display: flex;
	justify-content: space-between;
	margin: 0 auto;
	padding: 0 ${pxToRem(20)};
	max-width: ${pxToRem(400)};

	${getDesktopStyle(css`
		margin: 0;
		margin-right: ${pxToRem(36)};
		justify-content: initial;

		li + li {
			margin-left: ${pxToRem(20)};
		}
	`)};
`;

const MenuWrapper = styled.div`
	display: block;
	margin-top: ${pxToRem(36)};

	${getDesktopStyle(css`
		display: flex;
		margin-top: ${pxToRem(72)};
	`)}
`;

const MobileButtonWrapper = styled.div`
	margin-top: ${pxToRem(28)};
	text-align: center;
`;

const ContentWrapper = styled.div`
	margin: 0 auto;

	${getDesktopStyle(css`
		width: ${pxToRem(INNER_ELEMENT_WIDTH)};
	`)}
`;

const Wrapper = styled.section`
	background-color: ${WHITE};
	padding: ${pxToRem(80)} 0;
	color: ${BLACK};

	${getDesktopStyle(css`
		background-color: ${GRAY_01};
		padding: ${pxToRem(112)} 0;
		min-width: ${pxToRem(WINDOW_MIN_WIDTH)};
	`)}
`;

const TitleWrapper = styled.div`
	padding: 0 ${pxToRem(13)};

	${getDesktopStyle(css`
		padding: 0;
	`)}
`;

const CareWrapper = styled.div`
	padding: 0 ${pxToRem(24)};

	${getDesktopStyle(css`
		padding: 0;
	`)}
`;
